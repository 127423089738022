const __navBarHelpers = {
    isNewTech() {
        return document.querySelector("meta[name='o365-app-id']") !== null;
    },
    translate() {
        return (this.isNewTech() ? window.$translate : window.aT) || (x => x);
    },
    hideNavParams() {
        const queryParams = new URLSearchParams(window.location.search);
        const hideNavKey = [...queryParams.keys()].find(n => n.toLowerCase() === "hidenav")
        const hideNavValue = queryParams.get(hideNavKey);
        return !!hideNavValue && hideNavValue.toLowerCase() === "true";
    }
};

window["__navBarHelpers"] = __navBarHelpers;

const __dataAccess = {
    execProc({ procName, data }) {
        const isNewTech = __navBarHelpers.isNewTech();
        return fetch(isNewTech ? `/nt/api/data/${procName}` : `/api/apps/data/${procName}`, {
            method: "POST",
            body: isNewTech ?
                JSON.stringify({
                    Timeout: 30,
                    UseTransaction: true,
                    Operation: "execute",
                    ProcedureName: procName,
                    Values: data
                })
                : JSON.stringify({
                    timeout: 30,
                    operation: "execute",
                    procedure: procName,
                    ...data
                }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(r => r.json()).then(d => d.success);
    },
    getDataFromView({ viewName, whereClause, sortOrder, groupBy, maxRecords, distinctRows, fields, skip }) {
        const isNewTech = __navBarHelpers.isNewTech();
        return fetch(isNewTech ? `/nt/api/data/${viewName}` : `/api/apps/data/${viewName}`, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({
                "whereClause": whereClause || "",
                "sortOrder": sortOrder || [],
                "groupBy": groupBy || [],
                "maxRecords": maxRecords || 25,
                "distinctRows": distinctRows || false,
                "skip": skip || 0,
                "fields": fields || [],
                "operation": "retrieve",
                "viewName": viewName,
            }),
        }).then(r => r.json()).then(d => isNewTech ? d.success : d.success.data).then(d => maxRecords === 1 ? d[0] : d);
    }
};

window["__dataAccess"] = __dataAccess;
